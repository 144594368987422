  
import React,{ useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import FilterListIcon from '@material-ui/icons/FilterList';
import AdminService from '../../Services/AdminService';
import { useHistory } from "react-router-dom";


function createData(id,email,username, firstname, lastname, role) {
  return { id,email,username, firstname, lastname, role };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: 'email', numeric: false, disablePadding: true, label: 'email' },
  { id: 'username', numeric: false, disablePadding: false, label: 'User Name' },
  { id: 'firstname', numeric: false, disablePadding: false, label: 'First Name' },
  { id: 'lastname', numeric: false, disablePadding: false, label: 'Last Name' },
  { id: 'role', numeric: false, disablePadding: false, label: 'Role' },  
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },

}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, onFilterClick,onCreateUserClick,onEditUserClick,onEditCustomerAccountClick,onChangePasswordClick,onSearchUserChange} = props;


  

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Users
        </Typography>
      )}

      {numSelected > 0 ? (
        <div className={classes.sectionDesktop}>
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>          
        </Tooltip>   
        <Tooltip title="Edit">
          <IconButton aria-label="edit">
            <EditIcon onClick={event=>onEditUserClick(event)} />
          </IconButton>          
        </Tooltip>
        <Tooltip title="Manage Customer Accounts">
          <IconButton aria-label="Manage Customer Accounts">
            <EditIcon onClick={event=>onEditCustomerAccountClick(event)} />
          </IconButton>          
        </Tooltip>     
        <Tooltip title="Set Password">
          <IconButton aria-label="Set Password">
            <EditAttributesIcon onClick={event=>onChangePasswordClick(event)}/>
          </IconButton>          
        </Tooltip>     
        <Tooltip title="View Report">
          <IconButton aria-label="view report">
            <ReceiptIcon />
          </IconButton>          
        </Tooltip>     

        </div>
      ) : (
        <div className={classes.sectionDesktop}>
          <Tooltip title="Search User">
          <TextField                      
              fullWidth
              id="searcUser"
              label="Search User"
              name="searcUser"
              autoComplete="search-user"
              onChange={event => onSearchUserChange(event)}
            />
          </Tooltip>

          <Tooltip title="Filter list">          
            <IconButton aria-label="filter list" >
              <FilterListIcon onClick={event => onFilterClick(event, "Agent")} />
            </IconButton>          
          </Tooltip>
          <Tooltip title="Create User">
          
            <IconButton aria-label="Create User">
              <AddIcon onClick={event=>onCreateUserClick(event)}  />
            </IconButton>
        
          </Tooltip>          
        </div>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function UsersTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows,setRows] = React.useState([]);
  const [rowsData,setRowsData] = React.useState([]);
  const [selectedFilterRoleIndex,setSelectedFilterRoleIndex] = React.useState(0);
  const [selectedRole,setSelectedRole] = React.useState('All');
  const [userSearchFilter,setUserSearchFilter] = React.useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.email);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const isSelected = email => selected.indexOf(email) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


  const roles = ['Admin','Supplier','Agent','Customer','All'];

  const filterUsers = (event,role) => 
  {           
      var newIndex = (selectedFilterRoleIndex + 1) % 5;
      setSelectedFilterRoleIndex(newIndex);
      setSelectedRole(roles[newIndex]);
      var selectedRoleFilter = roles[newIndex];
      
      // if (selectedRole === 'All')
      // {
      //   setRows(rowsData);
      // }
      // else
      // {
      //   setRows(rowsData.filter(row => row.role == selectedRoleFilter) );
      // }

      setRows(rowsData.filter(row => (((row.role === selectedRoleFilter) || (selectedRoleFilter === 'All')) && (row.username.includes(userSearchFilter))) ));
  }

  const getUsersReturned = (data) => {			 
    var adjustedData = [];
    
    for (var rec of data)
    {              
        adjustedData.push(createData(rec.id,rec.email,rec.username, rec.firstName,rec.lastName,rec.role));
    }

    setRowsData(adjustedData);
    setRows(adjustedData);
}

  useEffect(() => {
    AdminService.getUsers(getUsersReturned);
	  
  },[]);

  const history = useHistory();

  const onCreateUserClickHandler = (event) =>
  {
      history.push("/createuser");
  }

  const onEditUserClickHandler = (event) =>
  {   
      let selctedUserId =  selected[0];
      let row = rows.find(row => row.id == selctedUserId);      

      history.push("/editUser/" + row.role + "/"  + selctedUserId);
  }

  const onEditCustomerAccountClickHandler = (event) =>
  {   
      let selctedUserId =  selected[0];
      let row = rows.find(row => row.id == selctedUserId);      

      if (row.role == "Customer")
      {
        history.push("/customeraccounts/"  + selctedUserId);
      }
      
  }

  const onChangePasswordClickHandler = (event) =>
  {   
      let selctedUserId =  selected[0];     
      history.push("/changepassword/"  + selctedUserId);            
  }

  const onSearchUserChangeHandler = (event) =>
  {   
    var searchString = event.target.value;
    setUserSearchFilter(searchString);    
    setRows(rowsData.filter(row => (((row.role === selectedRole) || (selectedRole === 'All')) && (row.username.includes(searchString))) ));
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} onFilterClick={filterUsers} 
        onCreateUserClick={onCreateUserClickHandler} onEditUserClick={onEditUserClickHandler} 
        onEditCustomerAccountClick={onEditCustomerAccountClickHandler}  
        onChangePasswordClick={onChangePasswordClickHandler}
        onSearchUserChange={onSearchUserChangeHandler}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.email}
                      </TableCell>
                      <TableCell align="left">{row.username}</TableCell>
                      <TableCell align="left">{row.firstname}</TableCell>
                      <TableCell align="left">{row.lastname}</TableCell>
                      <TableCell align="left">{row.role}</TableCell>                      
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}
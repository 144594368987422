import AuthService from './AuthService.js';
import ServiceConfig from './ServiceConfig';

// DEVELOPMENT
//const API_BASE_URL = 'http://localhost:5000/report/';
const API_BASE_URL =  ServiceConfig.getConfig() + 'report/';
// PRODUCTION
// const API_BASE_URL = 'http://3.126.83.121/report/';

class ReportsService {
	
	
    getAgentReport(handleResult)
	{
		var token = AuthService.getAuthHeaderValue();
									
        fetch(API_BASE_URL + 'agent', {
									      headers: new Headers({'Content-Type': 'application/json','Authorization':AuthService.getAuthHeaderValue()})
		}).then((resp) => resp.json())
			  .then(function(data) {
						
				if (data)
				{						
					handleResult(data);
				}
				else
				{					
					console.log('failed to get report');
				}
				
				
			  })
			  .catch(function(error) {
				console.log(JSON.stringify(error));
			  });	  	  	 
    }
		  
}

export default new ReportsService();
import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from "react-router-dom";
import useForm from '../../CustomHooks';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import AdminService from '../../Services/AdminService';

const initialState = { username: "", email: "", firstName: "", lastName: "", password: "", cellPhoneNumber: "", supplierId: null, enabled: false, feePercents: 0 };

function createData(id,userEmail, firstName, lastName) {
  return { id,userEmail, firstName, lastName};
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AgentDetailsEdit(props) {
  const classes = useStyles();
  const history = useHistory();

  //const {inputs, handleInputChange, handleSubmit} = useForm(createSubmit);
  const [open, setOpen] = React.useState(false);
  const [suppliers, setSuppliers] = React.useState([{ "id": 0, "userEmail": "", "firstName": "None", "lastName": "" }]);
  const [agentDetails, setAgentDetails] = React.useState(initialState);


  const getSuppliersReturned = (data) => {
    var adjustedData = [];
    
    adjustedData.push(createData("-1","","None",""));

    for (var rec of data)
    {                      
        adjustedData.push(createData(rec.id,rec.userEmail, rec.firstName,rec.lastName));
    }

    setSuppliers(adjustedData);    
  }

  const getAgentByIdReturned = (data) => {
    setAgentDetails(data);
  }

  useEffect(() => {
    AdminService.getSuppliers(getSuppliersReturned);

    // if edit and not create new
    if (props.id) {
      AdminService.getAgentById(props.id, getAgentByIdReturned);
    }

  }, []);


  const handleInputChange = event => {
    const { name, value } = event.target;
    setAgentDetails({ ...agentDetails, [name]: value });
  }

  const handleCheckBoxChange = event => {
    const { name, checked } = event.target;    
    setAgentDetails({ ...agentDetails, [name]: checked });
  };

  const saveAgentFinished = (data) => {
    props.handleCustomerSave(agentDetails);
    setAgentDetails(initialState);
    setOpen(true);
  }

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (props.id) {
      agentDetails.id = props.id;
    }

    AdminService.saveAgent(agentDetails, saveAgentFinished);
  }



  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="username"
              label="User Name"
              name="username"
              autoComplete="off"

              onChange={handleInputChange}
              value={agentDetails.username}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="off"
              onChange={handleInputChange}
              value={agentDetails.email}
            />
          </Grid>

          {!props.id &&
            <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="password"
              type="password"
              id="password"
              autoComplete="off"
              autoCapitalize="off"
              autoComplete="new-password"
              onChange={handleInputChange}
              value={agentDetails.password}
            />
          </Grid>
          }
          
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoComplete="not-complete"
              onChange={handleInputChange}
              value={agentDetails.firstName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lastName"
              onChange={handleInputChange}
              value={agentDetails.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="cellPhoneNumber"
              label="Phone"
              name="cellPhoneNumber"
              autoComplete="cellPhoneNumber"
              onChange={handleInputChange}
              value={agentDetails.cellPhoneNumber}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="feePercents"
              type="number"
              label="Fee From Success"
              name="feePercents"
              autoComplete="feePercents"
              onChange={handleInputChange}
              value={agentDetails.feePercents}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="customer-agent-native-simple">Supplier</InputLabel>
              <Select
                native
                value={agentDetails.supplierId}
                onChange={handleInputChange}
                inputProps={{
                  name: 'supplierId',
                  id: 'customer-agent-native-simple',
                }}
              >

                {suppliers.map((supplier) => (
                  <option value={supplier.id}>{supplier.firstName + " " + supplier.lastName + "(" + supplier.userEmail + ")"}</option>
                ))}

              </Select>
            </FormControl>
          </Grid>


          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox value={agentDetails.enabled}  onChange={handleCheckBoxChange} name="enabled" id="enabled" checked={agentDetails.enabled} color="primary" />}
              label="Active"
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {props.id ? "Save" : "Create"}
        </Button>
      </form>

    </Container>
  );
}
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
 
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 400,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

export default function Customer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      	 
		<Paper className={classes.paper}>
			<Grid container wrap="nowrap" spacing={2}>
			  <Grid item>
				<Avatar>1</Avatar>
			  </Grid>
			  <Grid item xs zeroMinWidth>
                    <Typography noWrap> Customer Report </Typography>
			  </Grid>
			</Grid>
		  </Paper>  
    </div>
  );
}
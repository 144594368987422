import React,{useState,useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory,useParams } from "react-router-dom";
import SecurityService from '../../Services/SecurityService.js';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ChangePassword(props) {
    const classes = useStyles();
	  const { id } = useParams();
	  const history = useHistory();
    const [userName, setUserName] = useState('');
    const [newPassword, setNewPassword] = useState('');
   
	 const handleSavePasswordByAdminReturned = () => {     
		 history.goBack();
	 }
	 	 	 
	const handleSubmit = () => {    		 
	  var request = {		 
		  userId: id,
		  newPassword: newPassword,
	  }
	    
	  SecurityService.changePassword(request,handleSavePasswordByAdminReturned);
  }


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change Password 
        </Typography>
        
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="New Password"
            type="password"
            id="newPassword"
            autoComplete="new-password"
            autoFocus
		      	value={newPassword}
		      	onChange={event=>setNewPassword(event.target.value)}
          />
          
          <Button
            onClick={handleSubmit}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Change
          </Button>
                  
      </div>      
    </Container>
  );
}
import React,{ useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Chart from './Common/Chart';
import MonthsFeesTable from './Common/MonthsFeesTable';
import ReportsService from '../Services/ReportsService';


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));


// Generate Sales Data
function createData(time, amount) {
    return { time, amount };
  }
 

export default function Dashboard() {
  const classes = useStyles();
 
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [chartData, setChartData] = useState([]);
  
  const agentReportReturned = (data) => {			 
    var adjustedData = [];

    for (var rec of data)
    {        
        adjustedData.push(createData(rec.feeDate,rec.fee));
    }

    setChartData(adjustedData);
}

  useEffect(() => {
    // Update the document title using the browser API
    //document.title = `You clicked ${count} times`;	
	// call 
	  ReportsService.getAgentReport(agentReportReturned);
	  
  },[]);


  return (
    <div className={classes.root}>
      <CssBaseline />
     
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} >
              <Paper className={fixedHeightPaper}>
                <Chart data={chartData} ></Chart>
              </Paper>
            </Grid>           
           
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <MonthsFeesTable  rows={chartData} />
              </Paper>
            </Grid>
          </Grid>
         
        </Container>
      </main>
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from "react-router-dom";
import useForm from '../../CustomHooks';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import AdminService from '../../Services/AdminService';


const initialState = { username: "", email: "", firstName: "", lastName: "", password: "", cellPhoneNumber: "", agentId: null, enabled: false, customerType: "Private" };
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function createData(id,userEmail, firstName, lastName) {
  return { id,userEmail, firstName, lastName};
}

export default function CustomerDetailsEdit(props) {
  const classes = useStyles();
  const history = useHistory();

  //const {inputs, handleInputChange, handleSubmit} = useForm(createSubmit);
  const [open, setOpen] = React.useState(false);
  const [agents, setAgents] = React.useState([{ "id": 0, "userEmail": "", "firstName": "None", "lastName": "" }]);
  const [customerDetails, setCustomerDetails] = React.useState(initialState);


  const getAgentsReturned = (data) => {
        
    var adjustedData = [];
    
    adjustedData.push(createData("-1","","None",""));

    for (var rec of data)
    {                      
        adjustedData.push(createData(rec.id,rec.userEmail, rec.firstName,rec.lastName));
    }
    
    
    setAgents(adjustedData);
  }

  const getCustomerByIdReturned = (data) => {    
    setCustomerDetails(data);    
  }

  useEffect(() => {
    AdminService.getAgents(getAgentsReturned);

    // if edit and not create new
    if (props.id) {
      AdminService.getCustomerById(props.id, getCustomerByIdReturned);
    }

  }, []);


  const handleInputChange = event => {
    const { name, value } = event.target;
    setCustomerDetails({ ...customerDetails, [name]: value });
  }

  const handleCheckBoxChange = event => {
    const { name, checked } = event.target;    
    setCustomerDetails({ ...customerDetails, [name]: checked });
  };

  const saveCustomerFinished = (data) => {
    props.handleCustomerSave(customerDetails);
    setCustomerDetails(initialState);
    setOpen(true);
  }

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (props.id) {
      customerDetails.id = props.id;
    }

    AdminService.saveCustomer(customerDetails, saveCustomerFinished);
  }



  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="username"
              label="User Name"
              name="username"
              autoComplete="off"

              onChange={handleInputChange}
              value={customerDetails.username}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="off"
              onChange={handleInputChange}
              value={customerDetails.email}
            />
          </Grid>

          {!props.id &&
            <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="password"
              type="password"
              id="password"
              autoComplete="off"
              autoCapitalize="off"
              autoComplete="new-password"
              onChange={handleInputChange}
              value={customerDetails.password}
            />
          </Grid>
          }
          
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoComplete="not-complete"
              onChange={handleInputChange}
              value={customerDetails.firstName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="lastName"
              onChange={handleInputChange}
              value={customerDetails.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="cellPhoneNumber"
              label="Phone"
              name="cellPhoneNumber"
              autoComplete="cellPhoneNumber"
              onChange={handleInputChange}
              value={customerDetails.cellPhoneNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="customer-type-native-simple">CustomerType</InputLabel>
              <Select
                native
                value={customerDetails.customerType}
                onChange={handleInputChange}
                inputProps={{
                  name: 'customerType',
                  id: 'customer-type-native-simple',
                }}
              >
                <option value={"Private"}>Private</option>
                <option value={"Company"}>Company</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="customer-agent-native-simple">Agent</InputLabel>
              <Select
                native
                value={customerDetails.agentId}
                onChange={handleInputChange}
                inputProps={{
                  name: 'agentId',
                  id: 'customer-agent-native-simple',
                }}
              >

                {agents.map((agent) => (
                  <option value={agent.id}>{agent.firstName + " " + agent.lastName + "(" + agent.userEmail + ")"}</option>
                ))}

              </Select>
            </FormControl>
          </Grid>


          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox value={customerDetails.enabled}  onChange={handleCheckBoxChange} name="enabled" id="enabled" checked={customerDetails.enabled} color="primary" />}
              label="Active"
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {props.id ? "Save" : "Create"}
        </Button>
      </form>

    </Container>
  );
}
import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory,useParams,useLocation } from "react-router-dom";
import useForm from '../../CustomHooks';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import AdminService from '../../Services/AdminService';

const initialState = { id: 0, year: 2020,month:1, sourceCurrency:"ILS", targetCurrency:"USD", rate:3 };
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function EditCurrency() {
  const classes = useStyles();
  const history = useHistory();

  const { id } = useParams();
  const location = useLocation();

  //const {inputs, handleInputChange, handleSubmit} = useForm(createSubmit);
  const [open, setOpen] = React.useState(false);  
  const [currencyDetails, setCurrencyDetails] = React.useState(initialState);
  const [isEdit,setIsEdit] = React.useState(false);

  const getCurrencyByIdReturned = (data) => {        
    setCurrencyDetails(data);    
  }

  useEffect(() => {
    
    
    // if edit and not create new
    if (location.pathname.includes("editcurrency")) {        
      setIsEdit(true);
      AdminService.getCurrencyById(id, getCurrencyByIdReturned);
    }
    else
    {
        setIsEdit(false);
    }

  }, []);


  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrencyDetails({ ...currencyDetails, [name]: value });
  }

  const handleCheckBoxChange = event => {
    const { name, checked } = event.target;    
    setCurrencyDetails({ ...currencyDetails, [name]: checked });
  };

  const saveFinished = (data) => {
    
    setCurrencyDetails(initialState);
    setOpen(true);
  }

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (isEdit) {
        currencyDetails.id = id;
    }    

    AdminService.saveCurrency(currencyDetails, saveFinished);
  }

  const handleCancel = () => {    
    history.goBack();
  };

  const handleClose = () => {
    setOpen(false);
    history.goBack();
  };


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="year"
              label="Year"
              name="year"
              autoComplete="off"
              onChange={handleInputChange}
              value={currencyDetails.year}
            />
          </Grid>
                             
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="month"
              label="Month"
              name="month"
              autoComplete="month"
              onChange={handleInputChange}
              value={currencyDetails.month}
            />
          </Grid>
        
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="sourceCurrency"
              label="Source Currency"
              name="sourceCurrency"
              autoComplete="sourceCurrency"
              onChange={handleInputChange}
              value={currencyDetails.sourceCurrency}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="targetCurrency"
              label="Target Currency"
              name="targetCurrency"
              autoComplete="targetCurrency"
              onChange={handleInputChange}
              value={currencyDetails.targetCurrency}
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="rate"
              label="Rate"
              name="rate"
              autoComplete="rate"
              onChange={handleInputChange}
              value={currencyDetails.rate}
            />
          </Grid>

          
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {isEdit ? "Save" : "Create"}
        </Button>

        <Button onClick={handleCancel} 
          color="primary"           
          variant="contained"
          fullWidth          
          >
            Cancel
        </Button>
      </form>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Transaction created Successfully
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
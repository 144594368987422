import AuthService from './AuthService.js';
import ServiceConfig from './ServiceConfig';

// DEVELOPMENT
const API_BASE_URL =  ServiceConfig.getConfig() + 'admin/'; // 'http://localhost:5000/admin/';

// PRODUCTION
//const API_BASE_URL = 'http://3.126.83.121/admin/';


class AdminService {
		
    getUsers(handleResult)
	{
		return this.getAll('getusers',handleResult);		 	 
	}
	
	getAgents(handleResult)
	{
		return this.getAll('getusersByRole/Agent',handleResult);		 	 		
	}


	getSuppliers(handleResult)
	{
		return this.getAll('getusersByRole/Supplier',handleResult);		 	 				
	}

	
	
	getAll(actionPath,handleResult)
	{
		var token = AuthService.getAuthHeaderValue();
									
        fetch(API_BASE_URL + actionPath, {
									      headers: new Headers({'Content-Type': 'application/json','Authorization':AuthService.getAuthHeaderValue()})
		}).then((resp) => {return this.processResponse(resp);})
			  .then(function(data) {								
				if (data)
				{						
					handleResult(data);
				}
				else
				{					
					console.log('failed to get ' + actionPath);
				}
				
				
			  })
			  .catch(function(error) {								
				console.log(JSON.stringify(error));
			  });	  	  
	}

	getDetailsById(id,actionPath,handleResult)
	{
		var token = AuthService.getAuthHeaderValue();
									
        fetch(API_BASE_URL + actionPath + id, {
									      headers: new Headers({'Content-Type': 'application/json','Authorization':AuthService.getAuthHeaderValue()})
		}).then((resp) => {return this.processResponse(resp);})
			  .then(function(data) {								
				if (data)
				{						
					handleResult(data);
				}
				else
				{					
					console.log('failed to get ' + actionPath);
				}
				
				
			  })
			  .catch(function(error) {								
				console.log(JSON.stringify(error));
			  });	  	  
	}

	processResponse(resp)
	{
		if (resp.status == 401)
		{			
			AuthService.logOut();
			return null;
		}
		else
		{
			return resp.json();
		}
	}

	getCustomerById(id, handleResult)
	{
		this.getDetailsById(id,"customer/get/",handleResult);
	}

	getAgentById(id, handleResult)
	{
		this.getDetailsById(id,"agent/get/",handleResult);
	}

	getSupplierById(id, handleResult)
	{
		this.getDetailsById(id,"supplier/get/",handleResult);
	}

	getAccountById(id, handleResult)
	{
		this.getDetailsById(id,"account/get/",handleResult);
	}


	getCustomerAccounts(id, handleResult)
	{
		this.getDetailsById(id,"account/getall/",handleResult);
	}

	getAccountTransactions(id, handleResult)
	{
		this.getDetailsById(id,"transactions/getbyaccount/",handleResult);
	}
	
	getTransactionById(id, handleResult)
	{
		this.getDetailsById(id,"transactions/get/",handleResult);
	}

	getCurrencyById(id, handleResult)
	{
		this.getDetailsById(id,"currency/get/",handleResult);
	}

	getCurrencyRates(handleResult)
	{
		this.getAll("currency/getall",handleResult)
	}

	getTracks(handleResult)
	{
		return this.getAll("track/getall",handleResult);
	}

	

	_saveDetails(details,actionPath,successHandler)
	{
		fetch(API_BASE_URL + actionPath, {
		method: 'POST',
		headers: new Headers({'Content-Type': 'application/json','Authorization':AuthService.getAuthHeaderValue()}),
		body: JSON.stringify(details)}).then((resp) => {return this.processResponse(resp);})
				.then(function(data) {					
					successHandler();			
					
				})
				.catch(function(error) {
					console.log(JSON.stringify(error));
				});	  	  	 
	}


	saveCustomer(customerDetails,successHandler)
	{
		this._saveDetails(customerDetails,'customer/save',successHandler);
	}

	saveAgent(agentDetails,successHandler)
	{
		this._saveDetails(agentDetails,'agent/save',successHandler);		
	}

	saveSupplier(supplierDetails,successHandler)
	{
		this._saveDetails(supplierDetails,'supplier/save',successHandler);		
	}

	saveAccount(accountDetails,successHandler)
	{
		this._saveDetails(accountDetails,'account/save',successHandler);		
	}

	saveTransaction(transactionDetails,successHandler)
	{
		this._saveDetails(transactionDetails,'transactions/save',successHandler);		
	}

	saveCurrency(currencyDetails,successHandler)
	{
		this._saveDetails(currencyDetails,'currency/save',successHandler);		
	}


	deleteById(id,actionPath,handleResult)
	{
		var token = AuthService.getAuthHeaderValue();
									
		fetch(API_BASE_URL + actionPath + id, {
										  method: 'POST',
									      headers: new Headers({'Content-Type': 'application/json','Authorization':AuthService.getAuthHeaderValue()})
		}).then((resp) => {return this.processResponse(resp);})
			  .then(function(data) {								
				if (data)
				{						
					handleResult(data);
				}
				else
				{					
					console.log('failed to delete ' + actionPath + id);
				}
				
				
			  })
			  .catch(function(error) {								
				console.log(JSON.stringify(error));
			  });	  	  
	}

	deleteCurrency(id,handleResult)
	{
		return this.deleteById(id,'currency/delete/',handleResult)
	}

	

				  
}

export default new AdminService();
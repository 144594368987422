
import AuthService from './AuthService.js';
import ServiceConfig from './ServiceConfig';
// DEVELOPMENT
const API_BASE_URL =  ServiceConfig.getConfig() + 'sec/'; //const API_BASE_URL = 'http://localhost:5000/sec/';

// PRODUCTION
// const API_BASE_URL = 'http://3.126.83.121/sec/';


class SecurityService {


    changePassword(request, successHandler) 
    {
        var operationPath = "admin/setpass";
        if (request.userId === "0")
        {            
            operationPath = "user/setpass";
        }


        fetch(API_BASE_URL + operationPath, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': AuthService.getAuthHeaderValue() }),
            body: JSON.stringify(request)
        }).then((resp) => { return this.processResponse(resp); })
            .then(function (data) {                
                successHandler();

            })
            .catch(function (error) {
                console.log(JSON.stringify(error));
            });
    }

    processResponse(resp)
	{
		if (resp.status == 401)
		{			
			AuthService.logOut();
			return null;
		}
		else
		{
			return resp.json();
		}
	}
}

export default new SecurityService();
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Route,Redirect, Link, BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import SignIn from './Components/SignIn';
import * as serviceWorker from './serviceWorker';
import AuthService from './Services/AuthService.js';
import CreateUser from './Components/Admin/CreateUser';
import ManageCustomerAccounts from './Components/Admin/ManageCustomerAccounts';
import RouteLayout from './Components/Layouts/RouteLayout';
import EditAccount from './Components/Admin/EditAccount';
import ChangePassword from './Components/Admin/ChangePassword';
import ManageAccountTransactions from './Components/Admin/ManageAccountTransactions';
import EditTransaction from './Components/Admin/EditTransaction';
import ManageCurrency from './Components/Admin/ManageCurrency';
import EditCurrency from './Components/Admin/EditCurrency';

const routing = (
  <Router>
    <div>      
	  <Route exact path="/" render={() => AuthService.getUserInfo() ? 
			(<App /> ):
			(<Redirect to="/signin" />)		  
	  } />
      <Route exact path="/signin" component={SignIn} />      
      <Route exact path="/createuser" component={CreateUser} />
      <Route exact path="/edituser/:userrole/:id" component={CreateUser} />
      <RouteLayout exact path="/customeraccounts/:id" component={ManageCustomerAccounts} />            
      <RouteLayout exact path="/createaccount/:id" component={EditAccount} />
      <RouteLayout exact path="/editaccount/:id" component={EditAccount} />
      <RouteLayout exact path="/managetransactions/:id" component={ManageAccountTransactions} />
      <RouteLayout exact path="/createtransaction/:id" component={EditTransaction} />
      <RouteLayout exact path="/edittransaction/:id" component={EditTransaction} />
      <RouteLayout exact path="/currency" component={ManageCurrency} />
      <RouteLayout exact path="/createcurrency" component={EditCurrency} />
      <RouteLayout exact path="/editcurrency/:id" component={EditCurrency} />

      <RouteLayout exact path="/changepassword/:id" component={ChangePassword} />
    </div>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
import CustomerDetailsEdit from './CustomerDetailsEdit';
import AgentDetailsEdit from './AgentDetailsEdit';
import SupplierDetailsEdit from './SupplierDetailsEdit';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function CreateUser() {
  const classes = useStyles();
  const history = useHistory();

  const saveCustomer = (request) => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    history.push('/');
  };

  const handleCancel = () => {    
    history.goBack();
  };

  const [open, setOpen] = React.useState(false);
  const { userrole, id } = useParams();
  const [role, setRole] = React.useState(userrole);


  useEffect(() => {
    if (!userrole) {
      setRole("Customer");
    }

  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>


        {id
          ? <Typography component="h1" variant="h5"> Edit User </Typography>
          : <Typography component="h1" variant="h5"> Create User </Typography>
        }


      {id
        ? <Typography  > Role:   {role} </Typography>
        :<Grid className={classes.form} container spacing={6}>
          <Grid item xs={12} spacing={2}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="role-native-simple">Role</InputLabel>
              <Select
                native
                value={role}
                onChange={e => setRole(e.target.value)}
                inputProps={{
                  name: 'role',
                  id: 'role-native-simple',
                }}
              >
                <option value={"Customer"}>Customer</option>
                <option value={"Agent"}>Agent</option>
                <option value={"Supplier"}>Supplier</option>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      }
        

        {role == 'Customer' &&
          <CustomerDetailsEdit handleCustomerSave={saveCustomer} id={id} />
        }
        {role == 'Agent' &&
          <AgentDetailsEdit handleCustomerSave={saveCustomer} id={id} />
        }

        {role == 'Supplier' &&
          <SupplierDetailsEdit handleCustomerSave={saveCustomer} id={id} />
        }

        <Button onClick={handleCancel} 
          color="primary"           
          variant="contained"
          fullWidth          
          >
            Cancel
        </Button>

        <Box mt={5}>
          <Copyright />
        </Box>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your Account created Successfully
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
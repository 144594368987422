
import AuthService from './AuthService.js';

// DEVELOPMENT
const API_BASE_URL = 'http://localhost:5000/';

// PRODUCTION
const PROD_API_BASE_URL = 'https://capi.optimus-algo.com/';


class ServiceConfig {
    getConfig()
    {
        if (process.env.NODE_ENV === 'production')
        {
            console.log('config prod');
            return PROD_API_BASE_URL;
        }
        else
        {
            console.log('config dev');
            return API_BASE_URL;
        }                
    }
}

export default new ServiceConfig();
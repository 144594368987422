import React , { useState,useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import AuthService from './Services/AuthService.js';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Console from './Components/Console.js';
import Customer from './Components/Customer.js';
import AgentReport from './Components/AgentReport.js';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import clsx from 'clsx';

import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';


const useStyles = makeStyles(theme => ({
	root: {
	  display: 'flex',
	},
	toolbar: {
	  paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
	  display: 'flex',
	  alignItems: 'center',
	  justifyContent: 'flex-end',
	  padding: '0 8px',
	  ...theme.mixins.toolbar,
	},
	appBar: {
	  zIndex: theme.zIndex.drawer + 1,
	  transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	  }),
	},
	
	menuButton: {
	  marginRight: 36,
	},
	menuButtonHidden: {
	  display: 'none',
	},
	title: {
	  flexGrow: 1,
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
	  flexGrow: 1,
	  height: '100vh',
	  overflow: 'auto',
	},
	container: {
	  paddingTop: theme.spacing(4),
	  paddingBottom: theme.spacing(4),
	},
	paper: {
	  padding: theme.spacing(2),
	  display: 'flex',
	  overflow: 'auto',
	  flexDirection: 'column',
	},
	fixedHeight: {
	  height: 240,
	},
  }));

  function Copyright() {
	return (
	  <Typography variant="body2" color="textSecondary" align="center">
		{'Copyright © '}
		<Link color="inherit" href="https://material-ui.com/">
		  Your Website
		</Link>{' '}
		{new Date().getFullYear()}
		{'.'}
	  </Typography>
	);
  }


function App() {
	
	const drawerWidth = 240;




const classes = useStyles();
	const history = useHistory();
	const [userRole, setUserRole] = useState('');
	
	const handleOnCick = (e) => {		 
		 AuthService.logOut();
		 history.push('/');
	 }

	 useEffect(() => {
		// Update the document title using the browser API
		//document.title = `You clicked ${count} times`;
		
		// call 
		setUserRole(AuthService.getUserRole());
		  
	  });

	 

  return (
<div className={classes.root}>
<CssBaseline />
<AppBar position="absolute" className={clsx(classes.appBar)}>
  <Toolbar className={classes.toolbar}>
	<IconButton
	  edge="start"
	  color="inherit"
	  aria-label="open drawer"            
	  className={clsx(classes.menuButton,  classes.menuButtonHidden)}
	>
	  <MenuIcon />
	</IconButton>
	<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
	  Optimus CRM 
	</Typography>
	<Button onClick={handleOnCick} >logout</Button>
  </Toolbar>

  
</AppBar>

<main className={classes.content}>
  <div className={classes.appBarSpacer} />
  <Container maxWidth="lg" className={classes.container}>
	<Grid container spacing={3}>
	  {/* Chart */}           
	 
	  {/* Recent Orders */}
	  <Grid item xs={12}>
		<Paper className={classes.paper}>
					{userRole == 'Admin' &&
						<Console />	
					}
					{userRole == 'Supplier' &&
						<AgentReport />	
					}
					{userRole == 'Agent' &&
						<AgentReport />	
					}
					
					{userRole == 'Customer' &&
						<Customer />
					}


		</Paper>
	  </Grid>
	</Grid>
	<Box pt={4}>
	  <Copyright />
	</Box>
  </Container>
</main>
</div>


  );
}

export default App;

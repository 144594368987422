import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory,useParams,useLocation } from "react-router-dom";
import useForm from '../../CustomHooks';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import AdminService from '../../Services/AdminService';

const initialState = { accoundId: 0, userId: 0, trackId: 1,creationDate:"", balanceCurrency: "ILS", currentBalance: 0, reportCurrency: "ILS", highWatermarkAmount: 0, dukasAccountId: "", enabled: false };
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function EditAccount() {
  const classes = useStyles();
  const history = useHistory();

  const { id } = useParams();
  const location = useLocation();

  //const {inputs, handleInputChange, handleSubmit} = useForm(createSubmit);
  const [open, setOpen] = React.useState(false);
  const [tracks, setTracks] = React.useState([{ "id": 0, "name": ""}]);
  const [accountDetails, setAccountDetails] = React.useState(initialState);
  const [isEdit,setIsEdit] = React.useState(false);


  const getTracksReturned = (data) => {
    setTracks(data);
  }

  const getAccountByIdReturned = (data) => {    
    setAccountDetails(data);    
  }

  useEffect(() => {
    AdminService.getTracks(getTracksReturned);
    
    // if edit and not create new
    if (location.pathname.includes("editaccount")) {        
      setIsEdit(true);
      AdminService.getAccountById(id, getAccountByIdReturned);
    }
    else
    {
        setIsEdit(false);
    }

  }, []);


  const handleInputChange = event => {
    const { name, value } = event.target;
    setAccountDetails({ ...accountDetails, [name]: value });
  }

  const handleCheckBoxChange = event => {
    const { name, checked } = event.target;    
    setAccountDetails({ ...accountDetails, [name]: checked });
  };

  const saveAccountFinished = (data) => {
    //props.handleCustomerSave(customerDetails);
    setAccountDetails(initialState);
    setOpen(true);
  }

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (isEdit) {
        accountDetails.id = id;
    }
    else
    {
        accountDetails.userId = id;
    }

    AdminService.saveAccount(accountDetails, saveAccountFinished);
  }

  const handleCancel = () => {    
    history.goBack();
  };

  const handleClose = () => {
    setOpen(false);
    history.goBack();
  };


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="balanceCurrency"
              label="Balance Currency"
              name="balanceCurrency"
              autoComplete="off"

              onChange={handleInputChange}
              value={accountDetails.balanceCurrency}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="reportCurrency"
              label="Report Currency"
              name="reportCurrency"
              autoComplete="off"
              onChange={handleInputChange}
              value={accountDetails.reportCurrency}
            />
          </Grid>
          
          
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="dukasAccountId"
              label="Dukascopy Account Id"
              name="dukasAccountId"
              autoComplete="not-complete"
              onChange={handleInputChange}
              value={accountDetails.dukasAccountId}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="creationDate"
              label="Account Openning Date"
              name="creationDate"
              autoComplete="creationDate"
              onChange={handleInputChange}
              value={accountDetails.creationDate}
            />
          </Grid>
        
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="account-track-native-simple">Track</InputLabel>
              <Select
                native
                value={accountDetails.trackId}
                onChange={handleInputChange}
                inputProps={{
                  name: 'trackId',
                  id: 'account-track-native-simple',
                }}
              >

                {tracks.map((track) => (
                  <option value={track.id}>{track.name}</option>
                ))}

              </Select>
            </FormControl>
          </Grid>


          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox value={accountDetails.enabled}  onChange={handleCheckBoxChange} name="enabled" id="enabled" checked={accountDetails.enabled} color="primary" />}
              label="Active"
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {isEdit ? "Save" : "Create"}
        </Button>

        <Button onClick={handleCancel} 
          color="primary"           
          variant="contained"
          fullWidth          
          >
            Cancel
        </Button>
      </form>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your Account created Successfully
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
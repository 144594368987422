import ServiceConfig from './ServiceConfig';

// DEVELOPMENT
const API_BASE_URL =  ServiceConfig.getConfig(); //const USER_API_BASE_URL = 'http://localhost:5000/';

// PRODUCTION
// const USER_API_BASE_URL = 'http://3.126.83.121/';

class AuthService {
	
	
    login(credentials,successLogin,failedLogin)
	{
        fetch(API_BASE_URL + 'authenticate', {
	  method: 'POST',
	  headers: { 'Content-Type': 'application/json' },
	  body: JSON.stringify(credentials)}).then((resp) => resp.json())
			  .then(function(data) {
				var token = data.token;
				
				if (token)
				{				
					 localStorage.setItem("userInfo", JSON.stringify(token));
					 localStorage.setItem("role", JSON.stringify(data.role));
					 localStorage.setItem("shouldResetPassword", JSON.stringify(data.shouldResetPassword));
					successLogin();
				}
				else
				{					
					failedLogin(data.error);
				}
				
				
			  })
			  .catch(function(error) {
				console.log(JSON.stringify(error));
			  });	  	  	 
    }

    getUserInfo(){
        return JSON.parse(localStorage.getItem("userInfo"));
    }

    getAuthHeaderValue() {
       return 'Bearer ' + this.getUserInfo();
    }

    logOut() {
		localStorage.removeItem("userInfo");
		localStorage.removeItem("role"); 
		localStorage.removeItem("shouldResetPassword");       
	}
	
	getUserRole() {
		return JSON.parse(localStorage.getItem("role"));
	}

	getShouldResetPassword() {
		return JSON.parse(localStorage.getItem("shouldResetPassword"));
	}

	disableShouldResetPassword() 
	{
		localStorage.setItem("shouldResetPassword", "false");
	}
}

export default new AuthService();